var constantCountry = {
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Åland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos (Keeling) Islands',
  CD: 'Congo, Democratic Republic of the',
  CF: 'Central African Republic',
  CG: 'Congo',
  CH: 'Switzerland',
  CI: "Côte d'Ivoire",
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cabo Verde',
  CW: 'Curaçao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands (Malvinas)',
  FM: 'Micronesia (Federated States of)',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom of Great Britain and Northern Ireland',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia and the South Sandwich Islands',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard Island and McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran (Islamic Republic of)',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KP: "Korea (Democratic People's Republic of)",
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: "Lao People's Democratic Republic",
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova, Republic of',
  ME: 'Montenegro',
  MF: 'Saint Martin (French part)',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'North Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  PN: 'Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Palestine, State of',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SX: 'Sint Maarten (Dutch part)',
  SY: 'Syrian Arab Republic',
  SZ: 'Eswatini',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor-Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Türkiye',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan, Province of China',
  TZ: 'Tanzania, United Republic of',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Holy See',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela (Bolivarian Republic of)',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  VN: 'Viet Nam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  null: 'Other countries',
  Others: 'Other countries',
};

var constantAdNetwork = {
  adx: 'AD(x)',
  admanager: 'AdManager',
  admob_adx: 'AdMob_AD(x)',
  adcolony: 'AdColony',
  adcolony_openbidding: 'AdColony(Open Bidding)',
  adcrony: 'AdCrony',
  adexchange: 'AdExchange',
  admob: 'AdMob',
  admob_company: 'AdMob(owned by customer)',
  adpie: 'AdPie',
  adpie_pmp: 'AdPie PMP',
  adpie_bidding: 'AdPie(Bidding)',
  adpie_bidding_lineitem: 'AdPie(Bidding Lineitem)',
  adfit: 'AdFit',
  adview: 'AdView',
  applovin: 'AppLovin',
  applovin_openbidding: 'AppLovin(Open Bidding)',
  appier: 'Appier',
  a4g: 'A4G',
  batmobi: 'BatMobi',
  buzzad: 'BuzzAd',
  cauly: 'Cauly',
  coupang: 'Coupang',
  criteo: 'Criteo',
  customtabs: 'Custom Tabs',
  dawin: 'Dawin',
  du: 'DU',
  facebook: 'Facebook',
  facebook_openbidding: 'Facebook(Open Bidding)',
  fyber: 'Fyber',
  five: 'Five',
  indexexchange: 'Index Exchange',
  indexexchange_openbidding: 'Index Exchange(Open Bidding)',
  ironsource: 'IronSource',
  liftoff: 'Liftoff',
  liftoff_openbidding: 'Liftoff(Open Bidding)',
  mezzomedia: 'MezzoMedia',
  mobfox: 'MobFox',
  mobon: 'Mobon',
  mobpower: 'MobPower',
  mobvista: 'Mobvista',
  moloco: 'Moloco',
  mopub: 'MoPub',
  pangle: 'Pangle',
  pubmatic: 'PubMatic',
  pubmatic_openbidding: 'PubMatic(Open Bidding)',
  pubnative: 'PubNative',
  smaato: 'Smaato',
  smaato_openbidding: 'Smaato(Open Bidding)',
  tapjoy: 'Tapjoy',
  tapjoy_openbidding: 'Tapjoy(Open Bidding)',
  tam: 'TAM',
  tnk: 'TNK',
  unityads: 'UnityAds',
  vungle: 'Vungle',
  yeahmobi: 'YeahMobi',
  youappi: 'YouAppi',
  zplayads: 'ZPLAY Ads',
  fluct: 'Fluct',
  fluct_openbidding: 'Fluct(Open Bidding)',
};

const AD_TYPE = {
  BANNER: 'banner',
  NATIVE_BANNER: 'nativebanner',
  MREC: 'mrec',
  NATIVE: 'native',
  INTERSTITIAL: 'interstitial',
  REWARDED_VIDEO: 'rewardedvideo',
  APP_WALL: 'appwall',
  LEADERBOARD: 'leaderboard',
};

const AD_TYPE_LABEL = {
  BANNER: 'Banner',
  NATIVE_BANNER: 'Native Banner',
  MREC: 'MREC',
  NATIVE: 'Native',
  INTERSTITIAL: 'Interstitial',
  REWARDED_VIDEO: 'Rewarded Video',
  APP_WALL: 'App Wall',
  LEADERBOARD: 'LeaderBoard',
};

const guideLinkMap = {
  'ad(x)': {
    banner: {
      aos: 'https://docs.adxcorp.kr/android/sdk-2.x-integration/ad-formats/banner-ad',
      ios: 'https://docs.adxcorp.kr/ios/sdk-2.x-integration/ad-formats/banner-ad',
      unity: 'https://docs.adxcorp.kr/unity/sdk-2.x-integration/ad-formats/banner-ad',
    },
    mrec: {
      aos: 'https://docs.adxcorp.kr/android/sdk-2.x-integration/ad-formats/banner-ad',
      ios: 'https://docs.adxcorp.kr/ios/sdk-2.x-integration/ad-formats/banner-ad',
      unity: 'https://docs.adxcorp.kr/unity/sdk-2.x-integration/ad-formats/banner-ad',
    },
    interstitial: {
      aos: 'https://docs.adxcorp.kr/android/sdk-2.x-integration/ad-formats/interstitial-ad',
      ios: 'https://docs.adxcorp.kr/ios/sdk-2.x-integration/ad-formats/interstitial-ad',
      unity: 'https://docs.adxcorp.kr/unity/sdk-2.x-integration/ad-formats/interstitial-ad',
    },
    native: {
      aos: 'https://docs.adxcorp.kr/android/sdk-2.x-integration/ad-formats/native-ad',
      ios: 'https://docs.adxcorp.kr/ios/sdk-2.x-integration/ad-formats/native-ad',
    },
    nativebanner: {
      aos: 'https://docs.adxcorp.kr/android/sdk-2.x-integration/ad-formats/native-ad',
      ios: 'https://docs.adxcorp.kr/ios/sdk-2.x-integration/ad-formats/native-ad',
    },
    rewardedvideo: {
      aos: 'https://docs.adxcorp.kr/android/sdk-2.x-integration/ad-formats/rewarded-ad/ad-x',
      ios: 'https://docs.adxcorp.kr/ios/sdk-2.x-integration/ad-formats/rewarded-ad/ad-x',
      unity: 'https://docs.adxcorp.kr/unity/sdk-2.x-integration/ad-formats/rewarded-ad/ad-x',
    },
  },
  admob: {
    rewardedvideo: {
      aos: 'https://docs.adxcorp.kr/android/sdk-2.x-integration/ad-formats/rewarded-ad/admob',
      ios: 'https://docs.adxcorp.kr/ios/sdk-2.x-integration/ad-formats/rewarded-ad/admob',
      unity: 'https://docs.adxcorp.kr/unity/sdk-2.x-integration/ad-formats/rewarded-ad/admob',
    },
  },
  mopub: {
    banner: {
      aos: 'https://docs.adxcorp.kr/android/sdk-1.x-integration/ad-formats/banner-ad',
      ios: 'https://docs.adxcorp.kr/ios/sdk-1.x-integration/ad-formats/banner-ad',
      unity: 'https://docs.adxcorp.kr/unity/sdk-1.x-integration/ad-formats/banner-ad',
    },
    mrec: {
      aos: 'https://docs.adxcorp.kr/android/sdk-1.x-integration/ad-formats/banner-ad',
      ios: 'https://docs.adxcorp.kr/ios/sdk-1.x-integration/ad-formats/banner-ad',
      unity: 'https://docs.adxcorp.kr/unity/sdk-1.x-integration/ad-formats/banner-ad',
    },
    interstitial: {
      aos: 'https://docs.adxcorp.kr/android/sdk-1.x-integration/ad-formats/interstitial-ad',
      ios: 'https://docs.adxcorp.kr/ios/sdk-1.x-integration/ad-formats/interstitial-ad',
      unity: 'https://docs.adxcorp.kr/unity/sdk-1.x-integration/ad-formats/interstitial-ad',
    },
    native: {
      aos: 'https://docs.adxcorp.kr/android/sdk-1.x-integration/ad-formats/native-ad',
      ios: 'https://docs.adxcorp.kr/ios/sdk-1.x-integration/ad-formats/native-ad',
    },
    nativebanner: {
      aos: 'https://docs.adxcorp.kr/android/sdk-1.x-integration/ad-formats/native-ad',
      ios: 'https://docs.adxcorp.kr/ios/sdk-1.x-integration/ad-formats/native-ad',
    },
    rewardedvideo: {
      aos: 'https://docs.adxcorp.kr/android/sdk-1.x-integration/ad-formats/rewarded-ad/mopub',
      ios: 'https://docs.adxcorp.kr/ios/sdk-1.x-integration/ad-formats/rewarded-ad/mopub',
      unity: 'https://docs.adxcorp.kr/unity/sdk-1.x-integration/ad-formats/rewarded-ad/mopub',
    },
  },
};

var accountTypeMap = {
  adx: 'AD(X)',
  partner: 'Partner',
};

const mediationTypeMap = {
  AdPie: ['bidding'],
  AdFit: ['waterfall'],
  AdManager: ['waterfall'],
  AdMob: ['waterfall'],
  AppLovin: ['waterfall'],
  Cauly: ['waterfall'],
  CustomTabs: ['waterfall'],
  Facebook: ['stastitics'],
  Fyber: ['waterfall'],
  MoPub: ['waterfall'],
  Pangle: ['waterfall'],
  Tapjoy: ['waterfall'],
  UnityAds: ['waterfall'],
  Vungle: ['waterfall'],
};

const adNetworkTypeSelMap = {
  banner: ['AdPie', 'AdFit', 'AdManager', 'AdMob', 'AppLovin', 'Cauly', 'Facebook', 'Fyber', 'MoPub', 'Vungle'],
  mrec: ['AdPie', 'AdFit', 'AdManager', 'AdMob', 'AppLovin', 'Facebook', 'Fyber', 'MoPub'],
  native: ['AdPie', 'AdFit', 'AdManager', 'AdMob', 'AppLovin', 'Facebook', 'MoPub', 'Pangle'],
  nativeBanner: ['AdPie', 'AdFit', 'AdManager', 'AdMob', 'AppLovin', 'Facebook', 'MoPub', 'Pangle'],
  interstitial: ['AdPie', 'AdFit', 'AdManager', 'AdMob', 'AppLovin', 'Cauly', 'CustomTabs', 'Facebook', 'Fyber', 'MoPub', 'Pangle', 'Tapjoy', 'UnityAds', 'Vungle'],
  rewardedvideo: ['AdPie', 'AppLovin', 'AdManager', 'AdMob', 'CustomTabs', 'Facebook', 'Fyber', 'MoPub', 'Pangle', 'Tapjoy', 'UnityAds', 'Vungle'],
};

const customEventClassMapForAdmob = {
  banner: {
    Cauly: {
      aos: 'com.google.ads.mediation.cauly.CaulyBanner',
      ios: 'CaulyCustomEventBanner',
    },
  },
  interstitial: {
    Cauly: {
      aos: 'com.google.ads.mediation.cauly.CaulyInterstitial',
      ios: 'CaulyCustomEventInterstitial',
    },
    Pangle: {
      aos: 'com.google.ads.mediation.pangle.PangleInterstitial',
      ios: 'PangleCustomEventInterstitial',
    },
  },
  rewardedvideo: {
    AdMob: {
      aos: 'com.google.ads.mediation.google.AdMobRewardedVideoAdapter',
      ios: 'AdMobCustomEventRewardedVideo',
    },
    AdColony: {
      aos: 'com.google.ads.mediation.adcolony.AdColonyRewardedVideoAdapter',
    },
    AdManager: {
      aos: 'com.google.ads.mediation.google.AdMobRewardedVideoAdapter',
      ios: 'AdMobCustomEventRewardedVideo',
    },
    Mobvista: {
      aos: 'com.google.ads.mediation.mintegral.MintegralRewardedVideoAdapter',
      ios: 'MobvistaCustomEventRewardedVideo',
    },
    MobPower: {
      aos: 'com.google.ads.mediation.mobpower.MobPowerRewardedVideoAdapter',
    },
    Batmobi: {
      aos: 'com.google.ads.mediation.batmobi.BatMobiRewardedVideoAdapter',
    },
    DU: {
      aos: 'com.google.ads.mediation.du.DURewardedVideoAdapter',
    },
    MoPub: {
      aos: 'com.google.ads.mediation.mopub.MoPubRewardedVideoAdapter',
      ios: 'MoPubCustomEventRewardedVideo',
    },
    IronSource: {
      aos: 'com.google.ads.mediation.ironsource.IronSourceRewardedAdapter',
    },
    YouAppi: {
      aos: 'com.youappi.sdk.mediation.admob.YouAppiRewardedVideo',
    },
    ZPlayAds: {
      aos: 'com.google.ads.mediation.zplay.ZPlayAdsRewardedVideoAdapter',
    },
    Five: {
      aos: 'com.google.ads.mediation.five.FiveRewardedVideoAdapter',
      ios: 'FiveCustomEventRewardedVideo',
    },
    Pangle: {
      aos: 'com.google.ads.mediation.pangle.PangleRewardedVideo',
      ios: 'PangleCustomEventRewardedVideo',
    },
  },
};

const customEventClassMapForAdx = {
  banner: {
    AdPie: {
      aos: 'com.adxcorp.ads.adapter.AdxAdPieBanner',
      ios: 'ADXAdPieBannerAd',
    },
    AdFit: {
      aos: 'com.adxcorp.ads.adapter.AdFitBanner',
      ios: '',
    },
    AdManager: {
      aos: 'com.adxcorp.ads.adapter.AdManagerBanner',
      ios: 'ADXAdManagerBannerAd',
    },
    AdMob: {
      aos: 'com.adxcorp.ads.adapter.AdMobBanner',
      ios: 'ADXAdMobBannerAd',
    },
    AppLovin: {
      aos: 'com.adxcorp.ads.adapter.AppLovinBanner',
      ios: 'ADXAppLovinBannerAd',
    },
    Cauly: {
      aos: 'com.adxcorp.ads.adapter.CaulyBanner',
      ios: 'ADXCaulyBannerAd',
    },
    Facebook: {
      aos: '',
      ios: '',
    },
    Fyber: {
      aos: 'com.adxcorp.ads.adapter.FyberBanner',
      ios: 'ADXFyberBannerAd',
    },
    MoPub: {
      aos: 'com.adxcorp.ads.adapter.MoPubBanner',
      ios: 'ADXMoPubBannerAd',
    },
    Vungle: {
      aos: 'com.adxcorp.ads.adapter.VungleBanner',
      ios: 'ADXVungleBannerAd',
    },
  },
  mrec: {
    AdPie: {
      aos: 'com.adxcorp.ads.adapter.AdxAdPieBanner',
      ios: 'ADXAdPieBannerAd',
    },
    AdFit: {
      aos: 'com.adxcorp.ads.adapter.AdFitBanner',
      ios: '',
    },
    AdManager: {
      aos: 'com.adxcorp.ads.adapter.AdManagerBanner',
      ios: 'ADXAdManagerBannerAd',
    },
    AdMob: {
      aos: 'com.adxcorp.ads.adapter.AdMobBanner',
      ios: 'ADXAdMobBannerAd',
    },
    AppLovin: {
      aos: 'com.adxcorp.ads.adapter.AppLovinBanner',
      ios: 'ADXAppLovinBannerAd',
    },
    Facebook: {
      aos: '',
      ios: '',
    },
    Fyber: {
      aos: 'com.adxcorp.ads.adapter.FyberBanner',
      ios: 'ADXFyberBannerAd',
    },
    MoPub: {
      aos: 'com.adxcorp.ads.adapter.MoPubBanner',
      ios: 'ADXMoPubBannerAd',
    },
  },
  native: {
    AdPie: {
      aos: 'com.adxcorp.ads.adapter.AdxAdPieNativeAd',
      ios: 'ADXAdPieNativeAd',
    },
    AdFit: {
      aos: 'com.adxcorp.ads.adapter.AdFitNativeAd',
      ios: '',
    },
    AdManager: {
      aos: 'com.adxcorp.ads.adapter.AdManagerNativeAd',
      ios: 'ADXAdManagerNativeAd',
    },
    AdMob: {
      aos: 'com.adxcorp.ads.adapter.AdMobNativeAd',
      ios: 'ADXAdMobNativeAd',
    },
    AppLovin: {
      aos: 'com.adxcorp.ads.adapter.AppLovinNativeAd',
      ios: '',
    },
    Facebook: {
      aos: '',
      ios: '',
    },
    MoPub: {
      aos: 'com.adxcorp.ads.adapter.MoPubNativeAd',
      ios: 'ADXMoPubNativeAd',
    },
    Pangle: {
      aos: 'com.adxcorp.ads.adapter.PangleNativeAd',
      ios: 'ADXPangleNativeAd',
    },
  },
  nativeBanner: {
    AdPie: {
      aos: 'com.adxcorp.ads.adapter.AdxAdPieNativeAd',
      ios: 'ADXAdPieNativeAd',
    },
    AdFit: {
      aos: 'com.adxcorp.ads.adapter.AdFitNativeAd',
      ios: '',
    },
    AdManager: {
      aos: 'com.adxcorp.ads.adapter.AdManagerNativeAd',
      ios: 'ADXAdManagerNativeAd',
    },
    AdMob: {
      aos: 'com.adxcorp.ads.adapter.AdMobNativeAd',
      ios: 'ADXAdMobNativeAd',
    },
    AppLovin: {
      aos: 'com.adxcorp.ads.adapter.AppLovinNativeAd',
      ios: '',
    },
    Facebook: {
      aos: '',
      ios: '',
    },
    MoPub: {
      aos: 'com.adxcorp.ads.adapter.MoPubNativeAd',
      ios: 'ADXMoPubNativeAd',
    },
    Pangle: {
      aos: 'com.adxcorp.ads.adapter.PangleNativeAd',
      ios: 'ADXPangleNativeAd',
    },
  },
  interstitial: {
    AdPie: {
      aos: 'com.adxcorp.ads.adapter.AdxAdPieInterstitialAd',
      ios: 'ADXAdPieInterstitialAd',
    },
    AdFit: {
      aos: 'com.adxcorp.ads.adapter.AdFitInterstitialAd',
      ios: '',
    },
    AdManager: {
      aos: 'com.adxcorp.ads.adapter.AdManagerInterstitialAd',
      ios: 'ADXAdManagerInterstitialAd',
    },
    AdMob: {
      aos: 'com.adxcorp.ads.adapter.AdMobInterstitialAd',
      ios: 'ADXAdMobInterstitialAd',
    },
    AppLovin: {
      aos: 'com.adxcorp.ads.adapter.AppLovinInterstitialAd',
      ios: 'ADXAppLovinInterstitialAd',
    },
    Cauly: {
      aos: 'com.adxcorp.ads.adapter.CaulyInterstitialAd',
      ios: 'ADXCaulyInterstitialAd',
    },
    CustomTabs: {
      aos: 'com.adxcorp.ads.adapter.CustomTabsInterstitialAd',
      ios: '',
    },
    Facebook: {
      aos: '',
      ios: '',
    },
    Fyber: {
      aos: 'com.adxcorp.ads.adapter.FyberInterstitialAd',
      ios: 'ADXFyberInterstitialAd',
    },
    MoPub: {
      aos: 'com.adxcorp.ads.adapter.MoPubInterstitialAd',
      ios: 'ADXMoPubInterstitialAd',
    },
    Pangle: {
      aos: 'com.adxcorp.ads.adapter.PangleInterstitialAd',
      ios: 'ADXPangleInterstitialAd',
    },
    Tapjoy: {
      aos: 'com.adxcorp.ads.adapter.TapJoyInterstitialAd',
      ios: 'ADXTapjoyInterstitialAd',
    },
    UnityAds: {
      aos: 'com.adxcorp.ads.adapter.UnityAdsInterstitialAd',
      ios: 'ADXUnityAdsInterstitialAd',
    },
    Vungle: {
      aos: 'com.adxcorp.ads.adapter.VungleInterstitialAd',
      ios: 'ADXVungleInterstitialAd',
    },
  },
  rewardedvideo: {
    AdPie: {
      aos: 'com.adxcorp.ads.adapter.AdxAdPieRewardedAd',
      ios: 'ADXAdPieRewardedAd',
    },
    AppLovin: {
      aos: 'com.adxcorp.ads.adapter.AppLovinRewardedAd',
      ios: 'ADXAppLovinRewardedAd',
    },
    AdManager: {
      aos: 'com.adxcorp.ads.adapter.AdManagerRewardedAd',
      ios: 'ADXAdManagerRewardedAd',
    },
    AdMob: {
      aos: 'com.adxcorp.ads.adapter.AdMobRewardedAd',
      ios: 'ADXAdMobRewardedAd',
    },
    CustomTabs: {
      aos: 'com.adxcorp.ads.adapter.CustomTabsRewardedAd',
      ios: '',
    },
    Facebook: {
      aos: '',
      ios: '',
    },
    Fyber: {
      aos: 'com.adxcorp.ads.adapter.FyberRewardedAd',
      ios: 'ADXFyberRewardedAd',
    },
    MoPub: {
      aos: 'com.adxcorp.ads.adapter.MoPubRewardedAd',
      ios: 'ADXMoPubRewardedAd',
    },
    Pangle: {
      aos: 'com.adxcorp.ads.adapter.PangleRewardedAd',
      ios: 'ADXPangleRewardedAd',
    },
    Tapjoy: {
      aos: 'com.adxcorp.ads.adapter.TapJoyRewardedAd',
      ios: 'ADXTapjoyRewardedAd',
    },
    UnityAds: {
      aos: 'com.adxcorp.ads.adapter.UnityAdsRewardedAd',
      ios: 'ADXUnityAdsRewardedAd',
    },
    Vungle: {
      aos: 'com.adxcorp.ads.adapter.VungleRewardedAd',
      ios: 'ADXVungleRewardedAd',
    },
  },
};

const customEventParamsMap = {
  AdPie: ['mid', 'sid'],
  AdFit: ['app_code'],
  AdManager: ['adunit_id'],
  AdMob: ['adunit_id'],
  AppLovin: ['zone_id'],
  Cauly: {
    aos: ['app_code'],
    ios: ['app_id', 'app_code'],
  },
  CustomTabs: ['url'],
  Facebook: ['placement_id'],
  Fyber: ['app_id', 'spot_id'],
  MoPub: ['adunit_id'],
  Pangle: ['app_id', 'placement_id'],
  Tapjoy: ['sdk_key', 'placement_name'],
  UnityAds: ['game_id', 'placement_id'],
  Vungle: ['app_id', 'placement_id'],
};

const paymentTypeMap = {
  early: '선정산',
  com2us: 'Com2us 정산',
  general: '일반정산',
};

const osTypeMap = {
  aos: 'Android',
  ios: 'iOS',
  aos_one: 'Android(One store)',
  aos_galaxy: 'Android(GalaxyStore)',
};

const firstTransferDateTypeMap = {
  0: '없음',
  15: '15일',
  30: '30일(말일)',
  35: '35일',
};

const secondTransferDateTypeMap = {
  0: '없음',
  60: '60일(말일)',
  65: '65일',
};

const reversePaymentTransferDateTypeMap = {
  35: '35일',
  60: '60일(말일)',
  65: '65일',
};

const aosStoreCategories = [
  'Art & Design',
  'Auto & Vehicles',
  'Beauty',
  'Books & Reference',
  'Business',
  'Comics',
  'Communications',
  'Dating',
  'Education',
  'Entertainment',
  'Events',
  'Finance',
  'Food & Drink',
  'Health & Fitness',
  'House & Home',
  'Libraries & Demo',
  'Lifestyle',
  'Maps & Navigation',
  'Medical',
  'Music & Audio',
  'News & Magazines',
  'Parenting',
  'Personalization',
  'Photography',
  'Productivity',
  'Shopping',
  'Social',
  'Sports',
  'Tools',
  'Travel & Local',
  'Video Players & Editors',
  'Weather',
];

const aosGameStoreCategories = ['Action', 'Adventure', 'Arcade', 'Board', 'Card', 'Casino', 'Casual', 'Educational', 'Music', 'Puzzle', 'Racing', 'Role Playing', 'Simulation', 'Sports', 'Strategy', 'Trivia', 'Word'];

const iosStoreCategories = [
  'Books',
  'Business',
  'Catalogs',
  'Education',
  'Entertainment',
  'Finance',
  'Food & Drink',
  'Health & Fitness',
  'Liftstyle',
  'Magazines & Newspapers',
  'Medical',
  'Music',
  'Navigation',
  'News',
  'Photo & Video',
  'Productivity',
  'Reference',
  'Shopping',
  'Social Networking',
  'Sports',
  'Stickers',
  'Travel',
  'Utilities',
  'Weather',
];

const iosGameStoreCategories = ['Action', 'Adventure', 'Arcade', 'Board', 'Card', 'Casino', 'Dice', 'Educational', 'Family', 'Music', 'Puzzle', 'Racing', 'Role Playing', 'Simulation', 'Sports', 'Strategy', 'Trivia', 'Word'];

const userStatusMap = {
  active: '활성',
  inactive: '비활성',
  password_locked: '비활성(비밀번호 잠김)',
};

const adNetworkAppSettings = {
  adcolony: ['app_id'],
  admanager: ['tagId'],
  admob: ['appId'],
  adpie: ['mid'],
  adpie_bidding: ['mid'],
  adpie_bidding_lineitem: ['mid'],
  adpie_pmp: ['mid'],
  batmobi: ['app_key', 'secretKey'],
  buzzad: ['appId'],
  cauly: ['app_id'],
  coupang: ['sub_id'],
  du: ['appId'],
  facebook: ['appId', 'secret', 'token'],
  five: ['appId'],
  fyber: ['app_id'],
  ironsource: ['app_key'],
  mezzomedia: ['mediaCode'],
  mobpower: ['app_id'],
  mobvista: ['app_id'],
  moloco: ['app_key'],
  pangle: ['app_id'],
  pubnative: ['appId'],
  tapjoy: ['sdk_key'],
  tnk: ['pub_id'],
  unityads: ['game_id'],
  vungle: ['app_id'],
  youappi: ['accessToken'],
  zplayads: ['app_id'],
};

const adunitIdMap = {
  appId: 'APP ID',
  app_id: 'APP ID',
  app_key: 'App Key',
  tagId: 'Tag ID',
  mid: 'Media ID',
  appKey: 'App Key',
  secretKey: 'Secret Key',
  secret: 'Secret',
  token: 'Token',
  pub_id: 'Pub ID',
  mediaCode: 'Media Code',
  sdk_key: 'SDK Key',
  game_id: 'Game ID',
  accessToken: 'Access Token',
  sub_id: 'Sub ID',
};

const notificationIntervalMinutes = 60 * 16;

const APP_STATUS = {
  DEV: 'dev',
  DISCONNECTED: 'disconnected',
  LIVE: 'live',
};

const APP_STATUS_LABEL = {
  dev: 'Integrate',
  disconnected: 'Inactive',
  live: 'Active',
};

const APP_ADS_STATUS = {
  APPLIED: 'applied',
  UNAPPLIED: 'unapplied',
  NEED_TO_UPDATE: 'need_to_update',
};

const APP_ADS_LABEL = {
  applied: 'Applied',
  unapplied: 'Unapplied',
  need_to_update: 'Need To Update',
};

const COMPANY_STATUS = {
  INTEGRATE: 'integrate',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

const COMPANY_STATUS_LABEL = {
  integrate: 'Integrate',
  active: 'Active',
  inactive: 'Inactive',
};

const APP_CATEGORY_LABEL = {
  non_game: 'Non Game',
  game: 'Game',
};

const openbiddingAdNetworks = ['facebook_openbidding', 'tapjoy_openbidding', 'liftoff_openbidding', 'adcolony_openbidding', 'smaato_openbidding', 'pubmatic_openbidding', 'indexexchange_openbidding', 'applovin_openbidding', 'fluct_openbidding'];

const CAMPAIGN_STATUS = {
  WAITING: 'waiting',
  RUNNING: 'running',
  PAUSED: 'paused',
  ENDED: 'ended',
  NO_CREATIVES: 'no_creatives',
};

const CAMPAIGN_STATUS_LABEL = {
  waiting: '대기',
  running: '진행',
  paused: '일시 정지',
  ended: '종료',
  no_creatives: '광고 없음',
};

const CAMPAIGN_DELIVERY_TYPE = {
  NO_LIMIT: 'backfill',
  EVEN: 'even',
  FAST: 'fast',
};

const COUNTRY_TYPE = {
  ALL: 'all',
  SELECT: 'include',
};

const MENU_AUTHORITY_ITEMS = [
  { text: 'Report', value: 'report' },
  { text: 'Apps', value: 'apps' },
  { text: 'Campaign', value: 'campaign' },
  { text: 'Finance', value: 'finance' },
];

const DAY_AND_TIME_TYPE = {
  ALL: 'all_day',
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
};

export default {
  constantAdNetwork,
  AD_TYPE,
  AD_TYPE_LABEL,
  constantCountry,
  mediationTypeMap,
  adNetworkTypeSelMap,
  customEventClassMapForAdx,
  customEventClassMapForAdmob,
  accountTypeMap,
  customEventParamsMap,
  paymentTypeMap,
  osTypeMap,
  firstTransferDateTypeMap,
  secondTransferDateTypeMap,
  reversePaymentTransferDateTypeMap,
  aosStoreCategories,
  aosGameStoreCategories,
  iosStoreCategories,
  iosGameStoreCategories,
  userStatusMap,
  adunitIdMap,
  adNetworkAppSettings,
  notificationIntervalMinutes,
  guideLinkMap,
  openbiddingAdNetworks,
  APP_STATUS,
  APP_STATUS_LABEL,
  APP_ADS_STATUS,
  APP_ADS_LABEL,
  COMPANY_STATUS,
  COMPANY_STATUS_LABEL,
  APP_CATEGORY_LABEL,
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_LABEL,
  CAMPAIGN_DELIVERY_TYPE,
  COUNTRY_TYPE,
  MENU_AUTHORITY_ITEMS,
  DAY_AND_TIME_TYPE,
};
